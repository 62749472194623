import React, { useState } from "react";
import "./style.css";
import Heading from "@tds/core-heading";
import Box from "@tds/core-box";
import PropTypes from "prop-types";
import Button from "@tds/core-button";
import FlexGrid from "@tds/core-flex-grid";
import { useTranslation } from "react-i18next";
import Text from "@tds/core-text";
import { fwaSchema } from "../schema";
import { addDays } from "date-fns";
import FormCol from "../../components/Form/FormCol";
import Notification from "@tds/core-notification";
import Field from "../../components/Form/Field";
import HairlineDivider from "@tds/core-hairline-divider";
import axios from "axios";
import Container from "../../components/Container";
import { Formik, Form } from "formik";
import config from "../../config";
import Spinner from "@tds/core-spinner";
import {
	//  scrollTop,
	getBuildingType,
	getBuildingPermission,
	getAppointmentDate,
	getPurchaseDeviceLocation
} from "../../utils";
import Tooltip from "@tds/core-tooltip";

const LandingPage = ({ history, location: { search } }) => {
	const [showFields, setShowFields] = useState();
	const [showSubmit, setShowSubmit] = useState();
	const [preferredDataSelected, setIsPreferredDate] = useState(false);
	const [t] = useTranslation();
	const { API_BASE } = config;
	const [isLoading, setIsLoading] = useState(false);
	const formikKeys = {
		isSmartHubReceived: "isSmartHubReceived",
		isChecklistCompleted: "isChecklistCompleted",
		appointmentDate: "appointmentDate",
		preferredDate1: "preferredDate1",
		preferredDate2: "preferredDate2"
	};
	const initialValues = {
		isSmartHubReceived: "",
		isChecklistCompleted: "",
		dealerEmail: "",
		businessName: "",
		streetNumber: "",
		streetName: "",
		unitNumber: "",
		province: "",
		postalCode: "",
		email: "",
		accountNumber: "",
		subscriberNumber: "",
		purchaseDeviceLocation: "",
		orderNumber: "",
		onsiteContactName: "",
		onsiteContactNumber: "",
		businessHours: "",
		buildingType: "",
		buildingPermission: "",
		appointmentDate: "",
		preferredDate1: "",
		preferredDate2: "",
		additionalInfo: ""
	};

	const excludeTime = (date) => {
		if (date !== "") {
			return date.toDateString().split(" ").slice(1).join(" ");
		} else {
			return date;
		}
	};

	const getBusinessAddress = (values) =>
		`${values.streetNumber} ${values.streetName} ${
			values.unitNumber && `${values.unitNumber} `
		}${values.city}, ${values.province} ${values.postalCode}`;

	const onFormSubmitted = (values) => {
		setIsLoading(true);
		const payload = {
			isSmartHubReceived: values.isSmartHubReceived,
			isChecklistCompleted: values.isChecklistCompleted,
			dealerEmail: values.dealerEmail || null,
			businessName: values.businessName,
			businessAddress: getBusinessAddress(values),
			email: values.email,
			accountNumber: values.accountNumber,
			subscriberNumber: values.subscriberNumber,
			purchaseDeviceLocation: values.purchaseDeviceLocation
				? values.purchaseDeviceLocation
				: " ",
			orderNumber: values.orderNumber,
			onsiteContactName: values.onsiteContactName,
			onsiteContactNumber: values.onsiteContactNumber,
			businessHours: values.businessHours,
			buildingType: values.buildingType ? values.buildingType : " ",
			buildingPermission: values.buildingPermission
				? values.buildingPermission
				: " ",
			appointmentDate: values.appointmentDate ? values.appointmentDate : " ",
			preferredDate1: excludeTime(values.preferredDate1),
			preferredDate2: excludeTime(values.preferredDate2),
			additionalInfo: values.additionalInfo
		};

		axios
			.post(`${API_BASE}install-5g-request/create`, payload)
			.then(({ fwaData }) => {
				setIsLoading(false);
				if (fwaData.success === 1) {
					history.push(`/success`);
				}
			})
			.catch(() => {
				setIsLoading(false);
				history.push(`/success`);
			});
	};

	const handleChange = (event, values, setValues) => {
		const {
			target: { value, name }
		} = event;

		if (name === formikKeys.isSmartHubReceived && value === t("No")) {
			setShowSubmit(false);
		}
		if (name === formikKeys.isSmartHubReceived && value === t("Yes")) {
			setShowSubmit(true);
		}
		if (name === formikKeys.isChecklistCompleted && value === t("No")) {
			setShowFields(false);
		}
		if (
			name === formikKeys.isChecklistCompleted &&
			value === t("Yes") &&
			values.isSmartHubReceived === t("Yes")
		) {
			setShowFields(true);
		}
		if (
			name === formikKeys.isSmartHubReceived &&
			value === t("Yes") &&
			values.isChecklistCompleted === t("Yes")
		) {
			setShowFields(true);
		}
		if (name === formikKeys.appointmentDate && value === t("Preferred Dates")) {
			setIsPreferredDate(true);
		}
		if (name === formikKeys.appointmentDate && value === t("Flexible Dates")) {
			setIsPreferredDate(false);
		}
	};

	return (
		<Container>
			<Spinner fullScreen label={t("SUBMITTING_INFO")} spinning={isLoading} />
			<Box between={3} inset={3} padding={100}>
				<Heading level='h2'>{t("LANDING_PAGE_TITLE")}</Heading>
				<p>{t("LANDING_PAGE_MSG_1")}</p>
				<Formik
					validationSchema={fwaSchema(t)}
					onSubmit={onFormSubmitted}
					enableReinitialize
					initialValues={initialValues}
				>
					{({ handleSubmit, errors, touched, values, setValues }) => (
						<Form
							onSubmit={handleSubmit}
							onChange={(e) => handleChange(e, values, setValues)}
						>
							<Box between={2} inset={3}>
								<Field
									name='isSmartHubReceived'
									type='radio'
									label={t("isSmartHubReceived")}
									values={values}
									onChange={(e) =>
										setValues({
											...values,
											isSmartHubReceived: e.target.value
										})
									}
									options={[
										t("isSmartHubReceived_Options.optionOne"),
										t("isSmartHubReceived_Options.optionTwo")
									]}
									touched={touched}
									errors={errors}
								/>
								{showSubmit && (
									<Box>
										<Field
											name='isChecklistCompleted'
											type='radio'
											label={t("isChecklistCompleted")}
											options={[
												t("isChecklistCompleted_Options.optionOne"),
												t("isChecklistCompleted_Options.optionTwo")
											]}
											values={values}
											touched={touched}
											errors={errors}
											onChange={(e) =>
												setValues({
													...values,
													isChecklistCompleted: e.target.value
												})
											}
										/>
										{showFields && (
											<Box>
												<Notification copy='en' variant='branded' id='fdgSKF'>
													<Text small>Note: </Text> Charges may apply if proper
													permissions have not been obtained during the site
													qualification process.
												</Notification>

												<div className='schedule-block'>
													<h1 className='schedule-install'>
														{t("Schedule Your Install")}
													</h1>
												</div>
												<Field
													name='dealerEmail'
													type='email'
													label={t("DEALER_EMAIL")}
													touched={touched}
													errors={errors}
													placeholder='Please Enter Email Address'
													onChange={(e) =>
														setValues({
															...values,
															dealerEmail: e.target.value
														})
													}
												/>
												<Field
													name='businessName'
													label={t("BUSINESS_NAME")}
													touched={touched}
													errors={errors}
													placeholder='Please Enter Business Name'
													onChange={(e) =>
														setValues({
															...values,
															businessName: e.target.value
														})
													}
												/>
												<Field
													type='Address'
													touched={touched}
													errors={errors}
													values={values}
													setValues={setValues}
													placeholder='Please Enter Business Address'
													readonly={false}
													disabled={false}
													isSameAs={false}
												/>
												<Field
													name='email'
													type='email'
													label={t("EMAIL")}
													touched={touched}
													errors={errors}
													placeholder='Please Enter Email Address'
													onChange={(e) =>
														setValues({
															...values,
															email: e.target.value
														})
													}
												/>
												<Field
													name='accountNumber'
													type='number'
													label={t("TELUS_ACCOUNT_NUMBER")}
													touched={touched}
													errors={errors}
													placeholder='Please Enter Account Number'
													onChange={(e) =>
														setValues({
															...values,
															accountNumber: e.target.value
														})
													}
												/>
												<Field
													name='subscriberNumber'
													type='mask'
													CustomField={"hello"}
													label={t("TELUS_SUBSCRIBER_NUMBER")}
													touched={touched}
													errors={errors}
													placeholder='Please Enter Subscriber Number'
													tooltip={
														<Tooltip className='tooltipbar' copy='en'>
															Hint: Phone number associated to ODU device
														</Tooltip>
													}
													onChange={(e) =>
														setValues({
															...values,
															subscriberNumber: e.target.value
														})
													}
												/>
												<Field
													name='purchaseDeviceLocation'
													type='select'
													label={t("PURCHASE_DEVICE_LOCATION")}
													touched={touched}
													errors={errors}
													onChange={(e) =>
														setValues({
															...values,
															purchaseDeviceLocation: e.target.value
														})
													}
													options={getPurchaseDeviceLocation(t)}
													placeholder={t(
														"Please Select Purchase Device Location"
													)}
												/>
												<Field
													name='orderNumber'
													label={t("ORDER_NUMBER")}
													touched={touched}
													errors={errors}
													tooltip={
														<Tooltip copy='en'>
															Hint: Found in your order confirmation email e.g.
															ES1223456
														</Tooltip>
													}
													placeholder='Please Enter Order Number'
													onChange={(e) =>
														setValues({
															...values,
															orderNumber: e.target.value
														})
													}
												/>
												<Field
													name='onsiteContactName'
													label={t("ONSITE_CONTACT_NAME")}
													touched={touched}
													errors={errors}
													placeholder='Please Enter Contact Name'
													onChange={(e) =>
														setValues({
															...values,
															onsiteContactName: e.target.value
														})
													}
												/>
												<Field
													name='onsiteContactNumber'
													type='mask'
													label={t("ONSITE_CONTACT_NUMBER")}
													touched={touched}
													errors={errors}
													placeholder='Please Enter Contact Number'
													onChange={(e) =>
														setValues({
															...values,
															onsiteContactNumber: e.target.value
														})
													}
												/>
												<Field
													name='businessHours'
													label={t("BUSINESS_HOURS")}
													touched={touched}
													errors={errors}
													placeholder='Please Enter Business Hours'
													onChange={(e) =>
														setValues({
															...values,
															businessHours: e.target.value
														})
													}
												/>
												<Field
													name='buildingType'
													type='select'
													label={t("BUILDING_TYPE")}
													touched={touched}
													errors={errors}
													onChange={(e) =>
														setValues({
															...values,
															buildingType: e.target.value
														})
													}
													options={getBuildingType(t)}
													placeholder={t("Please Select Building Type")}
												/>
												<Field
													name='buildingPermission'
													type='select'
													label={t("BUILDING_PERMISSION")}
													touched={touched}
													errors={errors}
													onChange={(e) =>
														setValues({
															...values,
															buildingPermission: e.target.value
														})
													}
													options={getBuildingPermission(t)}
													placeholder={t("Please Select Building Permissions")}
												/>
												<Field
													name='appointmentDate'
													type='appointmentDateSelect'
													label={t("APPOINTMENT_DATE")}
													touched={touched}
													errors={errors}
													onChange={(e) =>
														setValues({
															...values,
															appointmentDate: e.target.value
														})
													}
													options={getAppointmentDate(t)}
													placeholder={t("Please Select Appointment Date")}
												/>
												{preferredDataSelected && (
													<>
														<Notification copy='en' id='datepicker-notify'>
															Best effort will be taken to accommodate your
															selected dates
														</Notification>
														<Field
															name='preferredDate1'
															type='datePicker'
															label={t("PREFERRED_DATE1")}
															value={values.preferredDate1}
															touched={touched}
															errors={errors}
															minDate={addDays(new Date(), 7)}
															dateFormat='dd/MM/yyyy'
															placeholder='Please Enter 1st Preferred Date'
															onChange={(date) =>
																setValues({
																	...values,
																	[formikKeys.preferredDate1]: date
																})
															}
														/>
														<Field
															name='preferredDate2'
															type='datePicker'
															label={t("PREFERRED_DATE2")}
															value={values.preferredDate2}
															touched={touched}
															errors={errors}
															minDate={new Date() + 7}
															dateFormat='dd/MM/yyyy'
															placeholder='Please Enter 2nd Preferred Date'
															onChange={(e) =>
																setValues({
																	...values,
																	[formikKeys.preferredDate2]: e
																})
															}
														/>
													</>
												)}
												<Field
													name='additionalInfo'
													type='textNote'
													label={t("ADDITIONAL_INFO")}
													touched={touched}
													errors={errors}
													tooltip={
														<Tooltip copy='en'>
															Technician will require access to phone rooms,
															DMARC, on site contact
														</Tooltip>
													}
													onChange={(e) =>
														setValues({
															...values,
															additionalInfo: e.target.value
														})
													}
												/>
												<FlexGrid.Row horizontalAlign='center'>
													<FormCol md={6} xs={12}>
														<Button
															type='submit'
															variant='standard'
															rank='main'
														>
															{t(`submitButtonLabel`)}
														</Button>
													</FormCol>
												</FlexGrid.Row>
											</Box>
										)}
										<FlexGrid.Row>
											{showFields === false && (
												<>
													<Box between={4}>
														<HairlineDivider gradient />
														<Notification copy='en'>
															<Text bold>{t("NOTE ")}: </Text>{" "}
															{t("isChecklistMessage")}
														</Notification>
													</Box>
												</>
											)}
										</FlexGrid.Row>
									</Box>
								)}
								<FlexGrid.Row>
									{showSubmit === false && (
										<>
											<Box between={4}>
												<HairlineDivider gradient />
												<Notification copy='en'>
													<Text bold>{t("NOTE ")}: </Text>{" "}
													{t("isSmartHubMessage")}
												</Notification>
											</Box>
										</>
									)}
								</FlexGrid.Row>
							</Box>
						</Form>
					)}
				</Formik>
			</Box>
		</Container>
	);
};

LandingPage.propTypes = {
	onSubmit: PropTypes.func
};

export default LandingPage;
