import styled from "styled-components";

export const HeaderBar = styled.div`
  height: 80px;
  line-height: 80px;
  border-bottom: 1px solid rgb(216, 216, 216);
  @media screen and (max-width: 992px) {
    height: 59px;
    line-height: 59px;
  }
`;

export const LogoBar = styled.a`
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  height: 3rem;
  @media screen and (max-width: 992px) {
    height: 2rem;
    line-height: 59px;
  }
  img {
    height: 60%;
    width: 80%;
  }
`;
