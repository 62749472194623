import React from "react";
import FlexGrid from "@tds/core-flex-grid";
import Box from "@tds/core-box";
import { HeaderBar, LogoBar } from "../../styles/header";
import Logo from "./logo";

const Header = (props) => {
  return (
    <Box>
      <HeaderBar>
        <FlexGrid limitWidth>
          <FlexGrid.Row>
            <Box inset={3}>
              <LogoBar>
                <Logo />
              </LogoBar>
            </Box>
          </FlexGrid.Row>
        </FlexGrid>
      </HeaderBar>
    </Box>
  );
};

export default Header;
