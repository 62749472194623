export const scrollTop = () => {
  return window.scrollTo(0, 0);
};

export const getBuildingType = (t) => {
  const buildings = [
    "One story building - wood structure",
    "One story building - steel structure",
    "One story building - concrete/brick structure",
    "Multi story building - wood structure",
    "Multi story building - steel structure",
    "Multi story building - concrete/brick structure",
  ]
    .map((op) => t(op))
    .map((op) => ({ text: op, value: op }));

  return buildings;
};

export const getBuildingPermission = (t) => {
  const buildingPermission = [
    "You own the building and give permission to mount equipment on the outside of the building",
    "You lease/rent and have landlord permission to mount equipment on the outside of the building",
  ]
    .map((op) => t(op))
    .map((op) => ({ text: op, value: op }));

  return buildingPermission;
};

export const getAppointmentDate = (t) => {
  const appointmentDate = ["Preferred Dates", "Flexible Dates"]
    .map((op) => t(op))
    .map((op) => ({ text: op, value: op }));

  return appointmentDate;
};

export const getPurchaseDeviceLocation = (t) => {
  const purchaseDeviceLocation = ["In Store", "Shipped Direct from Telus"]
    .map((op) => t(op))
    .map((op) => ({ text: op, value: op }));

  return purchaseDeviceLocation;
};

export default {};